import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Home from './components/Home/HomePage'
import CookiePolicy from  './components/CookiePolicy/CookiePolicyPage'
import PrivacyPolicy from './components/Privacy/Privacy'
import TermsOfService from './components/TOS/TermsOfService'
import OAuthHomepage from './components/OAuthHomepage/OAuth'

const Routes = () => (
 <BrowserRouter>
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/oauth" component={OAuthHomepage} />
    <Route exact path="/privacy" component={PrivacyPolicy} />
    <Route exact path="/tos" component={TermsOfService} />
    <Route exact path="/cookiepolicy" component={CookiePolicy} />
    <Route path="/(notfound|[\s\S]*)/" component={Home} />
  </Switch>
 </BrowserRouter>
)

export default Routes
