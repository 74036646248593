import React, { Component } from 'react'
import logo from '../../app_logo.png';
import { Link } from 'react-router-dom';

class OAuthHomepage extends Component {

    render() {
        return (
        <section id='oauth'>
            <img src={logo} className="game-logo" alt="glogo" />
            <h1 style={{color:'orange'}}>
            Casual RPG
            </h1>
            <h4>What is Casual RPG?</h4>
            <p>
                A casual Role-Playing Game for casual android players
            </p>
            <h4>Personal Data</h4>
            <p>
                Personal data is used for registration/authentication and analytics. It is not shared or sold to any third-party. Please read our privacy for more information.
            </p>
            <p><Link to='/tos'>Terms of Service</Link></p>
            <p><Link to='/privacy'>Privacy Policy</Link></p>
            <p><Link to='/cookiepolicy'>Cookie Policy</Link></p>
        </section>
        )
    }
}

export default OAuthHomepage
