import React, { Component } from 'react'
import { Divider } from '@material-ui/core';


class TermsOfService extends Component {

    render() {
        return (
        <section id='tos'>
                <h1>Terms of Service for <span style={{color: 'orange'}}>Casual RPG</span></h1>
                <Divider />
                <p>
                    By accessing CasualRPG, you are agreeing to be bound by these Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials and asssets contained by CasualRPG are protected by applicable copyright and trade mark law.
                </p>
                <h4>Right to remove</h4>
                <p>
                    CasualRPG shall have no obligation (express or implied) to post or otherwise use your content and may at any time remove your content at any time and for any reason without prior notice. CasualRPG retains the right to remove your account and any in-game materials and assets associated with it without prior warning.
                </p>
                <h4>Removal Requests</h4>
                <p>
                    If you feel that there is content held within the game that you own has been submitted by somebody else and you want to take it down, please contact us at help@casualrpg.xyz and we will review your case.
                </p>
                <h4>Revisions and Errata</h4>
                <p>
                    The material that appears on CasualRPG could include technical, typographical, or photographic errors. This does not warrant that any of the materials and asssets contained by CasualRPG are accurate, complete, or current. CasualRPG make changes to the materials and assets contained by CasualRPG at any time without notice. This does not, however, make any commitment to update the materials.
                </p>
                <h4>Game Terms of Service Modifications</h4>
                <p>
                    CasualRPG may revise these terms of use for its game at any time without notice. By using this game you are agreeing to be bound by the current version of these Terms and Conditions of Use, and the in-game rules which are intented to supplement and not to replace or take precedence over the terms of service.
                </p>
                <h4>Disclaimer</h4>
                <p>
                    The materials and assets in-game are provided as is. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its web site or otherwise relating to such materials or on any sites linked to this site.
                </p>
                <h4>Representations and warranties</h4>
                <p>
                    You represent and warrant to as follows:
                    <ul>
                        <li>You are thirteen (13) years of age or older;</li>
                        <li>You have fully complied with all rules, terms and conditions contained in these Terms of Service;</li>
                        <li>The statements you make or stories you tell in the Submission as well as any other information you provide to are true and correct;</li>
                        <li>You have secured all rights from all other persons who appear in the Submission necessary to allow you to grant to all rights of such other persons in the Submission;</li>
                        <li>You have the right to grant the rights granted herein, and neither the Submission, nor the use of the Submission by ALA will infringe upon or violate the intellectual property rights or other rights of any other person or entity or any applicable laws.</li>
                        <li>
                            <b>In addition, the Submission does not contain any content:</b>
                            <ol>
                                <li>That is defamatory, libelous, abusive, threatening, racist, sexist, homophobic, vulgar, obscene, pornographic, offensive, indecent, or otherwise objectionable;</li>
                                <li>That promotes hatred or physical harm of any kind against any group or individual;</li>
                                <li>That is derogatory to members of a particular race, nationality, sex, sexual orientation or religion;</li>
                                <li>That promotes illegal activity; or</li>
                                <li>That contains any advertising or other commercial content.</li>
                            </ol>
                        </li>
                    </ul>
                    CasualRPG has the rights to lock your account and all of your in-game assets at any time with or without reason. This lock will restrict the account(s) from accessing certain areas of the game.
                </p>
                <h4>User Generated Content</h4>
                <p>
                    You understand and agree that when using this application, you may be exposed to content posted or submitted by users (collectively, User Generated Content), and that is not in any manner responsible for the User Generated Content, and that does not guarantee the accuracy, integrity, quality, or intellectual property rights of or relating to such User Generated Content, and that cannot assure that harmful, inaccurate, deceptive, offensive, threatening, defamatory, unlawful or otherwise objectionable User Generated Content will not appear within the application.
                </p>
                <p>
                    You acknowledge that by providing you with the ability to access and view User Generated Content within the application, is merely acting as a passive conduit for such distribution and is not undertaking any obligation or liability relating to any User Generated Content or activities of users within the application. Without limiting the generality of the foregoing, you acknowledge and agree that the information, materials and opinions expressed or included in any User Generated Content are not necessarily those of or its affiliated or related entities or content providers.
                </p>
                <p>
                    By submitting User Submissions to CasualRPG, you grant a perpetual royalty free, irrevocable, worldwide, exclusive, sublicenseable and transferable license to use, reproduce, modify, publish, translate, sell, transmit, distribute, prepare derivative works of, display, and perform and sub-license the Work(s) you supply or communicate to CasualRPG in any medium, now in existence or hereafter developed, for any purposes, including commercial use. You also warrant that any moral rights in User Submissions have been waived.
                    Links
                </p>
                <p>
                    CasualRPG has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by of the site. Use of any such linked web site is at the users own risk.
                </p>
                <Divider />
                <p>Last update: <span style={{color:'orange'}}>September 10, 2020</span></p>
        </section>
        )
    }
}

export default TermsOfService
