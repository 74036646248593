import React from 'react';
import CookieConsent from 'react-cookie-consent'
import Routes from './routes'
import './App.css';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-5XDZJXM'
};

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="App">
      <header className="Casual RPG">
        <Routes />
        <CookieConsent>
          This website uses cookies to enhance the user experience.
      </CookieConsent>

      </header>
    </div>
  );
}

export default App;
